import React, { useState } from "react";
import '../styles/PinModal.css';
import { baseurl } from "../utils/constants";
import axios from "axios";
import { useSelector } from "react-redux";

function PinModal({ isOpen, setIsOpen }) {
  const userData = useSelector((state) => state.userdata);
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [error, setError] = useState("");

  // Validation logic when submitting
  const handleSubmit = () => {
    if (!pin || !confirmPin) {
      setError("Both fields are required.");
      return;
    }

    if (pin !== confirmPin) {
      setError("Pins do not match.");
      return;
    }
    if (pin.length !==6) {
      setError("Pins should be 6 Characteres.");
      return;
    }

    //console.log(userData);
    let requestData ={
      requestTask :"updatepin",
      useremail: userData.email,
      userpin:pin
    }

    axios.post(baseurl, requestData)
    .then((result)=>{
      console.log(result.data)
      if (result.data=="updated"){
       
        setIsOpen(false);
        alert ("Congratulations you have updated your transaction pin.")
      }
    })


  };

  return isOpen ? (
    <div className="modal">
      <div className="modal-content">
        <h2>Set Your Transacation Pin</h2>
        {error && <p className="error">{error}</p>}
        <input
          type="password"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          placeholder="Enter Pin"
          className="input"
        />
        <br />
        <input
          type="password"
          value={confirmPin}
          onChange={(e) => setConfirmPin(e.target.value)}
          placeholder="Confirm Pin"
          className="input"
        />
        <br />
        <button onClick={handleSubmit} className="submit-btn">
          Submit
        </button>
        {/* <button onClick={() => setIsOpen(false)} className="close-btn">
          Close
        </button> */}
      </div>
    </div>
  ) : null;
}

export default PinModal;
