import React from "react";
import '../styles/LogoMarquee.css';
import cashAppLogo from "../images/cashapp.svg";
import revoltLogo from "../images/revolut.svg";
import transferwise from "../images/transferwise.svg";
import metrobankLogo from "../images/metrobank.svg";
import firstdirectLogo from "../images/firstdirect.svg";
import santanderLogo from "../images/santander.svg";
import halifaxLogo from "../images/halifax.svg";
import nationwideLogo from "../images/nationwide.svg";
import royalbankofscotlandLogo from "../images/royalbankofscotland.svg";
import barclaysLogo from "../images/barclays.svg";
import natwestLogo from "../images/natwest.svg";
import lloydsbankLogo from "../images/lloydsbank.svg";
import hsbcLogo from "../images/hsbc.svg";
import tdbankLogo from "../images/tdbank.svg";
import goldmansachsLogo from "../images/goldmansachs.svg";
import capitaloneLogo from "../images/capitalone.svg";
import truistbankLogo from "../images/truistbank.svg";
import pncLogo from "../images/pnc.svg";
import usbankLogo from "../images/usbank.svg";
import citibankLogo from "../images/citibank.svg";
import wellsfargoLogo from "../images/wellsfargo.svg";
import bankofamericaLogo from "../images/bankofamerica.svg";
import jpmorganLogo from "../images/jpmorgan.svg";

const LogoMarquee = () => {
  return (
    <div className="marquee-container">
      <marquee behavior="scroll" direction="left">
        <i className="fa-brands fa-paypal marqueeicon" title="PayPal"></i>
        <i className="fa-brands fa-stripe marqueeicon" title="Stripe"></i>
        <i className="fa-brands fa-google-pay marqueeicon" title="Google Pay"></i>
        <i className="fa-brands fa-cc-visa marqueeicon" title="Visa"></i>
        <i className="fa-brands fa-cc-mastercard marqueeicon" title="Mastercard"></i>
        <i className="fa-brands fa-cc-amex marqueeicon" title="American Express"></i>
        <i className="fa-brands fa-cc-discover marqueeicon" title="Discover"></i>
        <i className="fa-brands fa-apple-pay marqueeicon" title="Apple Pay"></i>
        <img src={cashAppLogo} alt="Cash App" className="marqueeImage" title="Cash App" />
        <img src={revoltLogo} alt="revolt" className="marqueeImage" title="revolt" />
        <img src={transferwise} alt="transferwise" className="marqueeImage" title="transferwise" />
        <img src={metrobankLogo} alt="metrobank" className="marqueeImage" title="Metrobank" />
  <img src={firstdirectLogo} alt="firstdirect" className="marqueeImage" title="FirstDirect" />
  <img src={santanderLogo} alt="santander" className="marqueeImage" title="Santander" />
  <img src={halifaxLogo} alt="halifax" className="marqueeImage" title="Halifax" />
  <img src={nationwideLogo} alt="nationwide" className="marqueeImage" title="Nationwide" />
  <img src={royalbankofscotlandLogo} alt="royalbankofscotland" className="marqueeImage" title="Royal Bank of Scotland" />
  <img src={barclaysLogo} alt="barclays" className="marqueeImage" title="Barclays" />
  <img src={natwestLogo} alt="natwest" className="marqueeImage" title="Natwest" />
  <img src={lloydsbankLogo} alt="lloydsbank" className="marqueeImage" title="Lloyds Bank" />
  <img src={hsbcLogo} alt="hsbc" className="marqueeImage" title="HSBC" />
  <img src={tdbankLogo} alt="tdbank" className="marqueeImage" title="TD Bank" />
  <img src={goldmansachsLogo} alt="goldmansachs" className="marqueeImage" title="Goldman Sachs" />
  <img src={capitaloneLogo} alt="capitalone" className="marqueeImage" title="Capital One" />
  <img src={truistbankLogo} alt="truistbank" className="marqueeImage" title="Truist Bank" />
  <img src={pncLogo} alt="pnc" className="marqueeImage" title="PNC" />
  <img src={usbankLogo} alt="usbank" className="marqueeImage" title="US Bank" />
  <img src={citibankLogo} alt="citibank" className="marqueeImage" title="Citibank" />
  <img src={wellsfargoLogo} alt="wellsfargo" className="marqueeImage" title="Wells Fargo" />
  <img src={bankofamericaLogo} alt="bankofamerica" className="marqueeImage" title="Bank of America" />
  <img src={jpmorganLogo} alt="jpmorgan" className="marqueeImage" title="JP Morgan" />
      </marquee>
    </div>
  );
};

export default LogoMarquee;
