import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NavLink, useNavigate } from 'react-router-dom';

const MySlider = () => {
  const navmove = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 6000, 
  };

  return (
    
    <Slider {...settings}>
     <div>
     <div className='sider-content'>
       <h1>EASY AND SECURED DIGITAL BANKING</h1>
       <p className='herodescription'>
       <span className='herowebname'>SafeHavenTB</span> is the best and secured way of performing
       transactions to people all over the world. No matter where you are in the world,
       you can save and transact with people conveniently and confidently.
       </p>
        <ul className='service-list'>
            <li>
                 <i class="fa-solid fa-square-check"></i>
                <p>Personal Banking</p>
            </li>
            <li>
                 <i class="fa-solid fa-square-check"></i>
                <p>Corporate Banking</p>
            </li>
            <li>
                 <i class="fa-solid fa-square-check"></i>
                <p>SME Banking</p>
            </li>
            <li>
                 <i class="fa-solid fa-square-check"></i>
                <p>Personal Loans</p>
            </li>
            
            </ul>     

           <div className='hero-button-wrapper'>
           <div className='button-div'onClick={()=>{navmove("/register")}}>Sign Up</div>
            <div className='button-div'onClick={()=>{navmove("/login")}}>Login</div>
           </div>
      </div>
     </div>
      <div>
      <div className='sider-content'>
       <h1>FAST AND STABLE BANKING</h1>
       <p >
       Join over 70,000 users from all over the world satisfied with our
       services. A place where everyone performs transactions with confidence. Even
       with a history of hefty technological investments and an even larger donations,
        consumer and investor confidence has never waned. 
       </p>
        <ul className='service-list'>
            <li>
                 <i class="fa-solid fa-square-check"></i>
                <p>Personal Banking</p>
            </li>
            <li>
                 <i class="fa-solid fa-square-check"></i>
                <p>Corporate Banking</p>
            </li>
            <li>
                 <i class="fa-solid fa-square-check"></i>
                <p>SME Banking</p>
            </li>
            <li>
                 <i class="fa-solid fa-square-check"></i>
                <p>Personal Loans</p>
            </li>
            
            </ul>     

           <div className='hero-button-wrapper'>
          
            <div className='button-div' onClick={()=>{navmove("/faq")}}>Know More</div>
           </div>
      </div>
      
      </div>
     
    </Slider>
  );
};

export default MySlider;
