import React, { useEffect, useState } from 'react'
import "../styles/DashboardTransactions.css"
import { baseurl, setCookie, getCookie } from "../utils/constants";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
 import { setUserTransactions } from "../redux/userTransactionSlice";

import { useNavigate } from 'react-router-dom';


function DashboardTransactions() {
  const [userLoadedTransactions, setUserLoadedTransactions]= useState([])
  const [loadingTrasactions, setLoadingTranscations]= useState(false)
  const dispatch = useDispatch()
  const navmove = useNavigate();
  
  const userdata = useSelector((state) => state.userdata);
  useEffect(() => {
    setLoadingTranscations(true);

    if ((document.cookie.indexOf('usersession') !== -1)) {

      async function fetchData() {
        try {
          const user = getCookie("usersession");
          const requestData = {
            requestTask: "getUserTransactions",
            email: user,
          };
          console.log(requestData)
          const result = await axios.post(baseurl, requestData);
         
          if (Array.isArray(result.data)) {
            console.log(result.data)
            setUserLoadedTransactions(result.data)
             setLoadingTranscations(false)
            dispatch(setUserTransactions(result.data))
             console.log("User Data is", result.data);
          }
        } catch (error) {
          alert("Poor internet connection!!!");
          // Handle errors here, e.g., log them or set an error state.
          console.error("Error fetching user data:", error);
        } finally {
          setLoadingTranscations(false);
        }
      }
  
      fetchData();
  

    }
    else{
      navmove('/login')
      }

   
  

    
  }, []);

  function formatNumber(number) {
    return number.toLocaleString();
   // return number
}

  return (
    <div className='DashboardTransactions'>
        {loadingTrasactions?(<i class="fa-solid fa-spinner fa-spin dashboardTabLoading"></i>):
        (
          <div>
              {
                userLoadedTransactions.length==0 ? (
                <div>
                  <p>No Transaction</p>
                </div>)
                :
                (
                  <div>
                    {
                      userLoadedTransactions.map((transaction, index)=>(
                        <div key={index}>
                         <div style={{
                          display:'flex',
                          justifyContent:'space-between',
                         }}>
                         
                         <h1 style={{ color: transaction.type=="CREDIT"? "#007CC5" :transaction.type=="DEBIT"?"red":"orange"}}>{transaction.type}</h1>
                         
                       
                          <p style={{ fontWeight:'bold',  color: transaction.type=="CREDIT"? "#007CC5" :transaction.type=="DEBIT"?"red":"orange"}}>{` ${transaction.type=="CREDIT"? "+":"-"}   ${userdata.currency} ${formatNumber(transaction.Amount)}`}</p>
                         </div>
                         <p>{transaction.STATUS ? `(${(transaction.STATUS)})`: ""}</p>
                          <p>{transaction.Date}</p>
                        </div>
                      ))

                      
                    }
                  </div>
                )
              }

          </div>
        )}
    </div>
  )
}

export default DashboardTransactions