import React, { useEffect, useState, useRef } from "react";
import "../styles/DashboardProfileSetting.css";
import { useDispatch, useSelector } from "react-redux";
import { setUserTransactions } from "../redux/userTransactionSlice";
import { baseurl } from "../utils/constants";
import axios from "axios";
import { Tuple } from "@reduxjs/toolkit";
import { setUserdata, clearUserdata } from '../redux/userdataSlice';

function DashboardProfileSetting() {
 const dispatch = useDispatch();
  const userdata = useSelector((state) => state.userdata);

  const profileInput = useRef();
  const [otp, setOTP] = useState("");
  const [newcode, setNewCode] = useState("");
  const [confirmNewCode, setConfirmNewCode] = useState("");
  const [profileImageAvailble, setProfileImageAvailable] = useState(false);
  const [profilepicture, setProfilePicture] = useState(null);
  const [userAddress, setUserAddress] = useState(userdata.useraddress);
  const [userEmail, setUserEmail] = useState(userdata.email);
  const [editingAddress, setEditingAddress] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [showEmailVerificationCode, setShowEmailVerificationCode]=useState("")
  const [sendingEmailVerifcationCode, setSendingEmailVerificationCode]= useState(false)
  const [updatingEmail, setUpdatingEmail]= useState(false)
  const [updatingAddress, setUpdatingAddress]= useState(false)
  const [emailverificationCode, setEmailVerifcationCode]= useState("")
  const [userEmailVerificationInput, setUserEmailVerifcationInput]= useState("")

  useEffect(() => {
        async function getUserData(){
            const requestData = {
                requestTask: "getLoginData",
                  email: userdata.email,                                
                };
                console.log(requestData)
                const userresult = await axios.post(baseurl, requestData);
                console.log( "USER DATA",userresult.data)
                 let  userData= userresult.data;
                
                 console.log("userdata",userData)
              console.log(userData.AccountNumber);
              console.log(userData.email);
              dispatch(setUserdata(userData));
        }


  getUserData();

    profilePictureCheck();

    
  }, []);

  const generateRandomCode = () => {
    // Generate a random 6-character validation code
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";
    for (let i = 0; i < 6; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return code;
  };
  async function profilePictureCheck() {
    let requestData = {
      requestTask: "getUserProfilePicture",
      email: userdata.email,
    };

    const result = await axios.post(baseurl, requestData);
    // console.log(result.data);

    if (result.data !== null) {
      let userprofilepix = await fromBase64(result.data, "profilepix");

      if (userprofilepix) {
        console.log("image conversion complete");
        const imagePreviewUrl = URL.createObjectURL(userprofilepix);
        setProfilePicture(imagePreviewUrl);
        setProfileImageAvailable(true);
      }
    }
  }
  function handleOTPChange(e) {
    setOTP(e.target.value);
  }
  function handleNewCodeChange(e) {
    setOTP(e.target.value);
  }
  function handleConfirmNewCodeChange(e) {
    setOTP(e.target.value);
  }


  async function UpdateEmailAddress(){

    if (userEmailVerificationInput !== emailverificationCode){
        alert("Invalid code.")
    }
    else{

        const requestData ={
            requestTask :"UpdateUserEmail",
            userID:userdata.id,
            emailUpdate:userEmail
        }
       
        await axios.post(baseurl, requestData)
        .then(result =>{
                console.log(result.data)
                if (result.data=="updated"){
                    setSendingEmailVerificationCode(false);
                    alert("Congratulations, You have sucessfuly updated your email.")
                    setShowEmailVerificationCode(false)
                    setEditingEmail(false);
                }
                else{
                    alert("Email update failed. Please contact support.")
                }
                setUpdatingAddress(false)
        })
        .catch(err=>console.log(err))





    }


  }


  async function UpdateAddress(){

    

        const requestData ={
            requestTask :"UpdateUserAddress",
            userID:userdata.id,
            addressUpdate:userAddress
        }
        setUpdatingAddress(true);
        await axios.post(baseurl, requestData)
        .then(result =>{
                console.log(result.data)
                if (result.data=="updated"){

                    // setSendingEmailVerificationCode(false);
                     alert("Congratulations, You have sucessfuly updated your address.")
                     setEditingAddress(false);
                }
                else{
                    alert("Address update failed. Please contact support.")
                }
                setUpdatingAddress(false)
        })
        .catch(err=>console.log(err))





    


  }


  async function sendEmailUpdateCode(){
         const verificationCode = generateRandomCode();
         console.log("VERIFCATION CODE : ", verificationCode)
            setEmailVerifcationCode(verificationCode)
    let requestData ={
            requestTask:"sendEmailVerification",
            email:userdata.email,
            firstName:userdata.firstName,
            verificationCode: verificationCode
         }
         setSendingEmailVerificationCode(true)
        axios.post (baseurl, requestData)
        .then(response=>{
            console.log(response.data)
            if (response.data=="sent"){
                alert("Please check your email for verification code.")
                setShowEmailVerificationCode(true)
            }

            setSendingEmailVerificationCode(false)
        })

  }



  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  function toBase64(file) {
    console.log("converting the image to base 24");
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Extract base64 part
      reader.onerror = (error) => reject(error);
    });
  }

  function fromBase64(base64, filename) {
    return new Promise((resolve, reject) => {
      try {
        // Determine the MIME type from the base64 string
        const mimeType = base64.startsWith("/9j/") ? "image/jpeg" : "image/png";

        // Convert the base64 string to a binary string
        const binaryString = atob(base64);

        // Create a Uint8Array from the binary string
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([bytes], { type: mimeType });

        // Adjust the filename extension based on the MIME type
        const extension = mimeType.split("/")[1];
        const fullFilename = `${filename}.${extension}`;

        // Create a File from the Blob
        const file = new File([blob], fullFilename, { type: mimeType });

        resolve(file);
      } catch (error) {
        reject(error);
      }
    });
  }

  //   const handleProfileImageChange = async (event) => {
  //     const file = event.target.files[0];

  //     // Check if the file exists and is an image
  //     if (file && file.type.startsWith("image/")) {
  //       setSelectedProfileImage(file);
  //       setProfilePicture(file);
  //       //   const result = await uploadProfilePicture(file)
  //       //   if( result){
  //       //     setProfileImageAvailable(true)
  //       //     setProfilePicture(file);
  //       //     alert("profile picture uplated.")
  //       //   }

  //       //   else{
  //       //     alert("Failed to upload profile picture.")
  //       //   }
  //     } else {
  //       alert("Please select a valid image file (e.g., .png, .jpg, .jpeg).");
  //       setSelectedProfileImage(null);
  //     }
  //   };

  const handleProfileImageChange = async (event) => {
    const file = event.target.files[0];

    // Check if the file exists and is an image
    if (file && file.type.startsWith("image/")) {
      // Generate a preview URL from the file
      const imagePreviewUrl = URL.createObjectURL(file);

      const result = await uploadProfilePicture(file);
      if (result) {
        alert("profile picture uplated.");

        setSelectedProfileImage(file);
        setProfileImageAvailable(true);
        setProfilePicture(imagePreviewUrl); // Set the preview URL as the profile picture
      } else {
        alert("Failed to upload profile picture.");
      }
    } else {
      alert("Please select a valid image file (e.g., .png, .jpg, .jpeg).");
      setSelectedProfileImage(null);
      setProfilePicture(null);
    }
  };

  const uploadProfilePicture = async (file) => {
    const base64Image = await toBase64(file);
    console.log("uploading image");
    let requestData = {
      requestTask: "uploadProfilePicture",
      userID: userdata.id,
      profileImage: base64Image,
    };

    const result = await axios.post(baseurl, requestData);
    console.log(result.data.trim());

    if (result.data == "uploaded") {
      console.log(result.data);
      console.log("uploading complete");
      return true;
    } else {
      console.log("uploading not compelete");
      console.log(result.data);
      return false;
    }
  };

  return (
    <div className="DashboardProfileSetting">
      <div className="profile-logo">
        <div>
          <div className="logo-container">
            {profileImageAvailble ? (
              <img src={profilepicture} alt="profileImage" />
            ) : (
              <i className="fa-regular fa-user"></i>
            )}
          </div>

          <input
            ref={profileInput}
            type="file"
            accept="image/*"
            onChange={handleProfileImageChange}
            style={{ display: "none" }}
          />
          <button
            style={{ fontSize: "10px", padding: "5px" }}
            onClick={() => {
              profileInput.current.click();
            }}
          >
            Upload/Change
          </button>

          <div className="banking-details">
            <p className="TDes">
              <b>{userdata.AccountNumber}</b>
            </p>
            <p className="account-type">Savings</p>
            <div
              className={
                userdata.AccountVerification == "NOT VERIFIED"
                  ? "notverified-status"
                  : "verified-status"
              }
            >
              {userdata.AccountVerification == "NOT VERIFIED"
                ? "NOT VERIFIED"
                : "VERIFIED"}
            </div>
          </div>
        </div>
      </div>

      <div className="profile-details">
        <p className="TDes">
          <b>Personal Information</b>
        </p>
        <div>
          <label className="profile-detail-label">Full Name:</label>
          <p>{`${userdata.firstName} ${userdata.lastName}`}</p>
        </div>

           {
            userdata.gender.length>2 &&
            <div> 
                <label className="profile-detail-label">Gender:</label>
                <p> {userdata.gender}</p>
            </div>
           }     

{
            userdata.DateOfBirth.length>2 &&
            <div> 
                <label className="profile-detail-label">DOB:</label>
                <p> {userdata.DateOfBirth}</p>
            </div>
           }     


        <div>
          <label className="profile-detail-label">Mobile:</label>
          <p>{userdata.phone}</p>
        </div>
        <div> 
          <label className="profile-detail-label">Email:</label>
         {
            showEmailVerificationCode?(
                <div className="editable-content">
                    <input
                    placeholder="insert verification code"
                    value={userEmailVerificationInput}
                    onChange={(e)=>{setUserEmailVerifcationInput(e.target.value)}}                   
                    />
                    <button onClick={()=>{UpdateEmailAddress()}}>{updatingEmail ? <i class="fa-solid fa-spinner fa-spin"></i>: "Update Email"}</button>
                </div>
            ):
            ( <div className="editable-content">
                <input
                  type="text"
                  value={userEmail}
                  readOnly={!editingEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                />
                {editingEmail ? (
                  <div>
                    <button onClick={()=>{sendEmailUpdateCode()}}>{sendingEmailVerifcationCode ? <i class="fa-solid fa-spinner fa-spin"></i>: "Update"}</button>
                    <button
                      onClick={() => {
                        if(!sendingEmailVerifcationCode){
                            setEditingEmail(false);
                        }
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={() => {
                        setEditingEmail(true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>)
         }
        </div>

       <div> 
          <label className="profile-detail-label">Address:</label>
          <div className="editable-content">
            <input
              type="text"
              value={userAddress}
              readOnly={!editingAddress}
              onChange={(e) => {
                setUserAddress(e.target.value);
              }}
            />
            {editingAddress ? (
              <div>
               <button onClick={()=>{UpdateAddress()}}>{updatingAddress ? <i class="fa-solid fa-spinner fa-spin"></i>: "Update"}</button>
                <button
                  onClick={() => {
                    if(!updatingAddress){
                        setEditingAddress(false);
                    }
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                <button
                  onClick={() => {
                    setEditingAddress(true);
                  }}
                >
                  Edit
                </button>
              </div>
            )}
          </div>
        </div>
        <div>
          <label className="profile-detail-label">Country:</label>
          <p>{userdata.country}</p>
        </div>
      </div>

      <div className="account-limits">
        <p className="TDes">
          <b>Account Limits</b>
        </p>
        <div>
          <label className="account-limits-label">
            Sending (Per Transaction):
          </label>
          <p>$1,000,000.00</p>
        </div>
        <div>
          <label className="account-limits-label">
            Receiving (Per Transaction):
          </label>
          <p>$500,000.00</p>
        </div>
        <div>
          <label className="account-limits-label">
            Daily Transaction Limit:
          </label>
          <p>$500,000.00</p>
        </div>
        <div>
          <label className="account-limits-label">Debit Card Limit:</label>
          <p>$500,000.00</p>
        </div>
        <div>
          <label className="account-limits-label">Maximum Balance:</label>
          <p>Unlimited</p>
        </div>
      </div>

      {/* <div className='account-limits'>
            <p className='TDes'><b>KYC</b></p>
           <ul>
            <li>- Driver's License</li>
            <li>- International Passport</li>
            <li>- National I.D Card</li>
           </ul>

           <input
           type='file'
           />
        </div>

        <div className='account-limits'>
            <p className='TDes'><b>Get OTP</b></p>
          <button style={{width:'200px'}}>Place Request</button>

          <div className="form-field">
        <label htmlFor="otp">OTP*</label>
        <input type="number" id="OTP" name="OTP" value={otp} onChange={handleOTPChange} />
      </div>
          <div className="form-field">
        <label htmlFor="newcode">New-pin*</label>
        <input type="number" value={newcode} onChange={handleNewCodeChange} />
      </div>
          <div className="form-field">
        <label htmlFor="newcode">Confirm-pin*</label>
        <input type="number" value={confirmNewCode} onChange={handleConfirmNewCodeChange} />
      </div>
      
     

        </div> */}
    </div>
  );
}

export default DashboardProfileSetting;
