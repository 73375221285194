import React from 'react'
import MySlider from './MySlider';
import Navbar from './Navbar';
import bkg from "../images/Bkg.png"
import "../styles/Hero.css"

function Hero() {
  return (
    <div>
        

        <div className='hero-container'>
        <div className='hero-left'  data-aos="slide-right" data-aos-duration="2000">
                 <MySlider/>
        </div>
        <div className='hero-right' data-aos="slide-left" data-aos-duration="2000">
            <img src={bkg} alt="hero-img" data-aos="fade-up"/>
        </div>
        </div>

    </div>
  )
}

export default Hero