import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { baseurl } from "../utils/constants";
import { setUserdata, clearUserdata } from '../redux/userdataSlice';
const PincodeAccess = ({
  amount,
  $currency,
  sendingChanel,
  recievingEntity,
  recivingentitypoint,
  setShowBillingFormat
}) => {
  const userdata = useSelector((state) => state.userdata);

  let dispatch = useDispatch();

  const [pincode, setPincode] = useState("");
  const [error, setError] = useState("");
  const [withdrawingFunds, setWithdrawingFunds] = useState(false);
 

  // Function to validate inputs
  function validateInputs() {
    setError("")

    if (pincode.length != 6) {
      setError("Pincode must be exactly 6 characters in length.");
      return false;
    }
    if (pincode != userdata.userPin) {
      console.log("PIN CODE =",pincode)
      console.log("USER PIN CODE =",userdata.userPin)
      setError("Invalid Pin");
      return false;
    }

    setError("");
    return true;
  };

  // Handle input change
  const handleChange = (e) => {
    const input = e.target.value;
    setPincode(input);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userdata);
    if (validateInputs()) {
  
      setWithdrawingFunds(true);
      let requestData = {
        requestTask: "withdrawalRequest",
        email: userdata.email,
        firstName: userdata.firstName,
        currency: userdata.currency,
        accountNumber: userdata.AccountNumber,
        amount:amount,
        sendingChanel: sendingChanel,
        recievingEntity: recievingEntity,
        recivingentitypoint: recivingentitypoint,
      };
      console.log(requestData)
      axios.post(baseurl, requestData)
      .then((response) => {
        console.log(response.data);
        setWithdrawingFunds(false);
        alert("Your withdrawal request has been recieved and is being reviewed.")
        setShowBillingFormat(false)
        dispatch(setUserdata(response.data));
      })
      .catch((error)=>
      {
        alert("An error occured please check your internet connection.")
        console.log(error)
      }
      
      )

     
    }
  };

  return (
    <div>
      <h3>Pincode Access</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={pincode}
          onChange={handleChange}
          placeholder="Enter 6 letter pincode"
          maxLength="6"
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button type="submit">{withdrawingFunds? <i class="fa-solid fa-spinner fa-spin"></i>:"Submit"}</button>
      </form>
    </div>
  );
};

export default PincodeAccess;
