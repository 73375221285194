import React from 'react';
import '../styles/Privacypolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className='privacypolicy'>
            <div className='privacypolicy-container'>
                <h1>Privacy Policy</h1>
                <div className="privacypolicy-body">
                    <p>
                        This Privacy Policy describes how safehavenTB ("we", "us", or "our") collects, uses, and discloses your personal information when you visit our website www.safehaventb.com (the "Site"), or use our services.
                    </p>
                    <h2>Information We Collect</h2>
                    <p>
                        We collect various types of personal information for the purposes of providing and improving our services. This may include:
                    </p>
                    <ul>
                        <li>Your name, contact details, and identification information when you apply for an account or use our services.</li>
                        <li>Information about your transactions with us and with other parties.</li>
                        <li>Information we receive from third parties, such as credit bureaus, affiliates, or other entities.</li>
                        <li>Information automatically collected when you visit our website, such as your IP address, browser type, and browsing behavior.</li>
                    </ul>
                    <h2>Use of Information</h2>
                    <p>
                        We may use the information we collect for various purposes, including:
                    </p>
                    <ul>
                        <li>Providing, maintaining, and improving our services.</li>
                        <li>Communicating with you, including responding to your inquiries and providing customer support.</li>
                        <li>Marketing and advertising our products and services.</li>
                        <li>Complying with legal and regulatory requirements.</li>
                        <li>Preventing fraud and enhancing the security of our services.</li>
                    </ul>
                    <h2>Information Sharing</h2>
                    <p>
                        We may share your personal information with third parties in the following circumstances:
                    </p>
                    <ul>
                        <li>With service providers who assist us in operating our business and providing our services.</li>
                        <li>With affiliates and business partners for marketing and advertising purposes.</li>
                        <li>With regulatory authorities and law enforcement agencies as required by law or to protect our legal rights.</li>
                        <li>In connection with a merger, acquisition, or sale of assets, where your personal information may be transferred as part of the transaction.</li>
                    </ul>
                    <h2>Your Choices</h2>
                    <p>
                        You have certain rights and choices regarding the personal information we collect and how it is used. You may:
                    </p>
                    <ul>
                        <li>Access and update your personal information.</li>
                        <li>Opt-out of receiving marketing communications from us.</li>
                        <li>Request the deletion of your personal information, subject to certain legal and contractual restrictions.</li>
                        <li>Exercise any other rights you may have under applicable privacy laws.</li>
                    </ul>
                    <h2>Contact Us</h2>
                    <p>
                        If you have any questions or concerns about our Privacy Policy or our privacy practices, you may contact us at support@safehavenTB.com.
                    </p>
                    <p>
                        Last updated: [2024]
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
