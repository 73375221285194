import React, {useEffect} from "react";
import Hero from "../Hero";
import aboutusImage from "../../images/about-us.jpg";
import "../../styles/Home.css";
import TabComponent from "../TabComponent";
import CardComponent1 from "../CardComponent1";
import CardComponent2 from "../CardComponent2";
import CountCard from "../CountCard";
import CardComponent3 from "../CardComponent3";
import CardComponent4 from "../CardComponent4";
import customer1 from "../../images/2.jpg";
import customer2 from "../../images/3.jpg";
import customer3 from "../../images/4.jpg";
import FAQs from "../FAQs";
import LogoMarquee from "../LogoMarquee";



export default function Home() {


  return (
    <div className="home-container" >
      <Hero />
      <div
        className="about-us-wrapper"
        style={{
          backgroundColor: "white",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
        data-aos="fade-up"
      >
        <div className="left">
          <img  src={aboutusImage} alt="" data-aos="fade-up" data-aos-duration="2000"  />
        </div>

        <div className="right">
          <div data-aos="fade-up">
            <h1 className="Theader">About Us</h1>
            <p className="TDes">
              Some years back, a dedicated team set out to revolutionize banking
              by introducing a savings app accessible to all. This marked the
              inception of safehavenTB Bank. Today, our resolve is stronger
              than ever as we unveil a Central Bank-licensed microfinance bank,
              committed to optimizing your finances without burdening you with
              excessive fees. Visit safehavenTB for tools to monitor your
              spending, increase savings, and make informed financial decisions.
              Wherever you are, whoever you may be, our mission is driven by
              your needs. We understand the frustrations of traditional banking,
              and we're here to pave a smoother path for all.
            </p>
          </div>
          <div>
            <TabComponent data-aos="fade-up" />
          </div>
        </div>
      </div>


      <div className="why-choose-us-container" data-aos="fade-up">
        <div className="why-choose-us-wrapper">
          <h1 className="Theader">Why Us?</h1>
          <p className="TDes">
            Here are some of the many features that define our uniqueness.
          </p>

          <div className="card-container">
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-solid fa-wallet"></i>}
                header={"Multiple Payment options"}
                description={
                  "We offer support for various payment methods including Visa, MasterCard, bank transfers, cryptocurrency, and more."
                }
              />
            </div>
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-solid fa-globe"></i>}
                header={"World Coverage"}
                description={
                  "We offer services in 80% of countries across the globe, spanning various continents."
                }
              />
            </div>
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-solid fa-hand-holding-dollar"></i>}
                header={"Incredible Transaction Fee"}
                description={
                  "Our transaction fees and rates are exceptionally low for all customers and market participants alike."
                }
              />
            </div>
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-solid fa-key"></i>}
                header={"Secured Transactions"}
                description={
                  "Your finances are safeguarded by our advanced technologies, providing protection against digital thefts and hacks."
                }
              />
            </div>
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-solid fa-user-lock"></i>}
                header={"Strong Security"}
                description={
                  "We provide unbeatable protection against DDoS attacks through comprehensive data encryption for all your transactions."
                }
              />
            </div>
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-solid fa-headset"></i>}
                header={"24/7 Support"}
                description={
                  "Our customer care service is available at all times to assist you and provide solutions to all your needs."
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/* INTEREST RATES SECTION */}
      <div className="why-choose-us-container"data-aos="fade-up" data-aos-duration="2000">
        <div className="why-choose-us-wrapper">
          <h1 className="Theader">Our Interest Rates</h1>
          <p className="TDes">
            We understand the needs of our customers very much that our interest
            rates are attractive.
          </p>
          <div className="ourservices-cards">
            <div data-aos="slide-right">
              <CardComponent2
                accountType="Personal"
                percentage="2%"
                minimumAmount="1,000"
                intervalType="Partial"
              />
            </div>

            <div data-aos="slide-left">
              <CardComponent2
                accountType="Corporate"
                percentage="3%"
                minimumAmount="10,000"
                intervalType="Fixed"
              />
            </div>
          </div>
        </div>
      </div>

      {/* A Summary of Our Journey */}
      <div className="our-journey" data-aos="fade-up" data-aos-duration="2000">
        <div className="why-choose-us-wrapper">
          <h1 className="Theader">A Summary of Our Journey</h1>
          <p className="TDes our-journey-description">
            We have over the years gannered loads of experiences that has
            consequently boosted our portfolio in world financial services.
          </p>

          <div className="count-cards-container">
            <div data-aos="fade-down">
              <CountCard
                icon={<i class="fa-solid fa-users"></i>}
                number={7002550}
                description="Active  users"
              />
            </div>
            <div data-aos="fade-down">
              <CountCard
                icon={<i class="fa-solid fa-clock-rotate-left"></i>}
                number={30036}
                description="Running Days"
              />
            </div>
            <div data-aos="fade-down">
              <CountCard
                icon={<i class="fa-solid fa-trophy"></i>}
                number={212}
                description="Won Awards"
              />
            </div>
            <div data-aos="fade-down">
              <CountCard
                icon={<i class="fa-solid fa-earth-europe"></i>}
                number={183}
                description="Global Pressence"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Our Breif History */}
      {/* <div className="our-history-container">
        <div className="our-history-wrapper">
          <div className="left">
            <h1 className="Theader">A brief history about us</h1>
            <p className="TDes">
              "safehavenTB," established in 2012 by socially-minded
              entrepreneurs, aimed to provide accessible financial services to
              low-income individuals and small businesses. Initially focusing on
              micro-loans for local micro-entrepreneurs, the company quickly
              expanded its operations to meet growing demand, opening branches
              in multiple cities. Offering a range of services including savings
              accounts, insurance, and money transfers, they also innovated with
              mobile banking solutions. A 2013 investment from a major venture
              capital firm facilitated further expansion, leading to becoming
              one of the country's largest microfinance companies by 2015.
              Throughout its journey, safehavenTB remained committed to
              empowering the underbanked and fostering economic growth.
            </p>
          </div>
          <div className="right">
            <YouTube videoId="Gc2en3nHxA4" className="youtube-video"/>
          </div>
        </div>
      </div> */}
      {/* Our Process is Simple and Short */}
      <div className="our-process-container" data-aos="fade-up" data-aos-duration="2000">
        <div className="our-process-wraper">
          <h1 className="Theader process-header">
            Our Process is Simple and Short
          </h1>

          <div className="processes-container">
            <div data-aos="fade-in" data-aos-duration="2000" onClick={()=>{window.location.assign("/register")}}>
              <CardComponent3
                number={1}
                icon={<i class="fa-solid fa-user-pen"></i>}
                header={"Create Your Account"}
                description={
                  "Begin by registering and verifying your identity to swiftly obtain a new account."
                }
              />
            </div>
            <div data-aos="fade-in" data-aos-duration="4000">
              <CardComponent3
                number={2}
                icon={<i class="fa-solid fa-gears"></i>}
                header={"Customize Your Profile"}
                description={
                  "After logging in, tailor your account by adding a new wallet and obtaining a unique account number along with a transaction pin."
                }
              />
            </div>
            <div data-aos="fade-in" data-aos-duration="6000">
              <CardComponent3
                number={3}
                icon={<i class="fa-solid fa-wallet"></i>}
                header={"Add Funds to Your Account"}
                description={
                  "Initiate deposits to enable transactions and unlock access to our array of services. Experience the security of our payment system safeguarding your finances and information."
                }
              />
            </div>
            <div data-aos="fade-in" data-aos-duration="8000">
              <CardComponent3
                number={4}
                icon={<i class="fa-solid fa-money-bill-trend-up"></i>}
                header={"Engage in Transactions"}
                description={
                  "Discover our seamless services as you execute a variety of transactions effortlessly."
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/*What Our Clients Say About Us*/}
      <div className="client-reviews" data-aos="fade-up" data-aos-duration="2000">
        <div className="client-reviews-wrapper">
          <h1 className="Theader">What Our Clients Say About Us</h1>
          <p className="TDes review-description">
            Our expertise in financial services has bettered the lives of our
            clients greatly, their testimony has encouraged us greatly.
          </p>

          <div className="review-container">
            <div data-aos="fade-down" >
              <CardComponent4
                review={
                  "safehavenTB is one of the best performing microfinance companies I have ever experienced. Their transaction process is incredibly seamless!"
                }
                image={customer1}
                name={"safehavenTB"}
              />
            </div>
            <div data-aos="fade-down">
              <CardComponent4
                review={
                  "Great bank with great vision. Their customer service is quite impressive. Their transaction fees are incredibly low, which is also amazing."
                }
                image={customer2}
                name={"safehavenTB"}
              />
            </div>
            <div data-aos="fade-down">
              <CardComponent4
                review={
                  "This is just awesome! One of the best services I've had. You can trust safehavenTB and deposit your funds. Their loans are really helpful and convenient too."
                }
                image={customer3}
                name={"safehavenTB"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="FAQs-container" data-aos="fade-up" data-aos-duration="2000">
        <div className="FAQ-wrapper">
          <FAQs />
        </div>
      </div>

      <LogoMarquee/>

      <div className="Get-Started-container" data-aos="fade-up" data-aos-duration="2000">
        <div className="get-started-wrapper">
          <div className="left">
            <div className="form-holder">
              <h1 className="Theader">Ready to get started?</h1>
              <form>
                <input placeholder="Enter Name *" />
                <input placeholder="Enter Email *" />
                <input placeholder="Enter Subject" />
                <textarea rows={5} placeholder="Message Body *"></textarea>

                <button>Send Message</button>
              </form>
            </div>
          </div>

          <div className="right">
            <h1 className="Theader">
              Looking for an easy and secured place to save your money?
            </h1>
            <p className="TDes">
              Search no more! Click on the button below to get started.
            </p>
            <button
              className="btn-get-solution"
              onClick={() => {
                window.location.assign("/faq");
              }}
            >
              Get Your Solution
            </button>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}
