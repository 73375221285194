import React, { useState, useRef, useEffect } from "react";
//import countriesData from './countriesData'; // Import the dataset for countries, states, and cities
import "../../styles/Registration.css";
import axios from "axios";
import registerbkg from "../../images/register.jpg";
import { baseurl } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { click } from "@testing-library/user-event/dist/click";
import { countryData } from "../../utils/CountryData";

const RegistrationPage = () => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryCurrency, setSelectedCountryCurrency] = useState("");
  const navmove = useNavigate();
  const firstName = useRef(null);
  const lastName = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const confirmPassword = useRef(null);
  const regButton = useRef(null);
  const regConfirmDiv = useRef(null);
  const [isTandCisChecked, setTandC] = useState(false);
  const [isFrelancer, setFreelancer] = useState(false);

  const [formError, setFormError] = useState("");
  const [validationCodeError, setValidationCodeError] = useState("");
  const [loadingRegEmail, setLoadingRegEmail] = useState(false);
  const [loadingRegUser, setLoadingRegUser] = useState(false);

  const [generatedCode, setGeneratedCode] = useState("");

  const [regCodetime, setRegCodeTime] = useState(0); // 5 seconds
  const [isRunning, setIsRunning] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  function handleCountrySelect(e) {
    try {
      const countryIndex = countryData.findIndex(
        (obj) => obj.name === e.target.value
      );

      if (countryData[countryIndex].currency.symbol == false) {
        setSelectedCountryCurrency(countryData[countryIndex].currency.code);
      } else {
        setSelectedCountryCurrency(countryData[countryIndex].currency.symbol);
      }

      setSelectedCountry(e.target.value);
      console.log("Selected Country", e.target.value);
      console.log(
        "Selected Country Currency",
        countryData[countryIndex].currency.symbol
      );
    } catch (error) {
      setSelectedCountry("");
      setSelectedCountryCurrency("");
    }
  }
  function disableRegInputs() {
    firstName.current.disabled = true;
    lastName.current.disabled = true;
    email.current.disabled = true;
    password.current.disabled = true;
    confirmPassword.current.disabled = true;
  }
  function enableRegInputs() {
    firstName.current.disabled = false;
    lastName.current.disabled = false;
    email.current.disabled = false;
    password.current.disabled = false;
    confirmPassword.current.disabled = false;
  }

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dateofbirth: "",
    address: "",
    mobilenumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  // const [validationCode, setValidationCode] = useState('');
  let validationCode = "";
  const [emailValidationCode, setEmailValidationCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, // Trim the value to remove leading/trailing spaces
    }));
  };

  function verifyInputs() {
    let emailpattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.firstName.trim() || formData.firstName.trim().length < 2) {
      setFormError("Invalid First Name");
      window.scrollTo(0, 0);
      return false;
    } 
    else if (
      !formData.lastName.trim() ||
      formData.lastName.trim().length < 2
    ) {
      setFormError("Invalid Last Name");
      return false;
      window.scrollTo(0, 0);
    } 

    else if (!formData.gender.trim())  
    {
      setFormError("Please select your gender");
      return false;
      window.scrollTo(0, 0);
    } 

    else if (!formData.dateofbirth.trim())  
      {
        setFormError("Please select your date of birth");
        return false;
        window.scrollTo(0, 0);
      } 
    
    else if (
      !formData.mobilenumber.trim() ||
      formData.mobilenumber.trim().length < 10
    ) {
      setFormError("Invalid Phone Number");
      window.scrollTo(0, 0);
      return false;
    } else if (!formData.address.trim()) {
      setFormError("Address is Required");
      window.scrollTo(0, 0);
      return false;
    } else if (!selectedCountry.trim()) {
      setFormError("Please select your Nationality");
      window.scrollTo(0, 0);
      return false;
    } else if (!formData.email.trim() || !emailpattern.test(formData.email)) {
      setFormError("Invalid email");
      window.scrollTo(0, 0);
      return false;
    } else if (!formData.password.trim() || formData.password.length < 8) {
      setFormError(
        "Invalid Passowrd. Password should be atleast 8 characters long."
      );
      window.scrollTo(0, 0);
      return false;
    } else if (
      !formData.confirmPassword.trim() ||
      formData.confirmPassword.length < 8
    ) {
      setFormError(
        "Invalid Passowrd Confirmation. Password should be atleast 8 characters long."
      );
      window.scrollTo(0, 0);
      return false;
    } else if (formData.password !== formData.confirmPassword) {
      setFormError("Passwords do not match");
      window.scrollTo(0, 0);
      return false;
    } else if (isTandCisChecked == false) {
      setFormError("Read and accept the terms and condition");
      window.scrollTo(0, 0);
      return false;
    } else {
      setFormError("");
      return true;
    }
  }

  const handleSendCode = async () => {
    if (verifyInputs()) {
      try {
        setLoadingRegEmail(true);
        disableRegInputs();
        const randomCode = generateRandomCode();
        setGeneratedCode(randomCode);
        const requestData = {
          requestTask: "sendRegsitrationOTP",
          recipientName: formData.firstName,
          email: formData.email,
          OTP: randomCode,
        };

        console.log(requestData);

        const result = await axios.post(baseurl, requestData);
        console.log(result.data.trim());

        if (result.data.trim() == "error") {
          setFormError("An error occured. Please try again.");
          setLoadingRegEmail(false);
          enableRegInputs();
          window.scrollTo(0, 0);
        } else if (result.data.trim() == "userfound") {
          setFormError("This email is registered. Please Login");
          setLoadingRegEmail(false);
          enableRegInputs();
          window.scrollTo(0, 0);
        } else if (result.data.trim() == "email-sent") {
          setLoadingRegEmail(false);
          setIsCodeSent(true);
          setFormError("");
          setValidationCodeError("");
          window.scrollTo(0, 0);
        } else {
          console.log(result.data);
          setLoadingRegEmail(false);
          console.log("could not understand result");
        }
      } catch (error) {
        alert("An error occured please check your internet connection.");
      }
    } else {
      window.scrollTo(0, 0);
    }
  };
  function generateBankAccountNumber() {
    let accountNumber = "";
    for (let i = 0; i < 10; i++) {
      accountNumber += Math.floor(Math.random() * 10);
    }
    return accountNumber;
  }
  // ===================================REGISTER USER====================================

  async function registerNewUser() {
    setLoadingRegUser(true);

    if (emailValidationCode == generatedCode) {
      setValidationCodeError("");

      let accountNumber = generateBankAccountNumber();

      const requestData = {
        requestTask: "registernewuser",
        firstName: formData.firstName,
        lastName: formData.lastName,
        mobilenumber: formData.mobilenumber,
        address: formData.address,
        country: selectedCountry,
        currency: selectedCountryCurrency,
        email: formData.email,
        password: formData.password,
        accountNumber: accountNumber,
        gender: formData.gender,
        dateofbirth: formData.dateofbirth
      };

      const result = await axios.post(baseurl, requestData);

      if (result.data == "registration-complete") {
        alert("Registration complete");
        setLoadingRegUser(false);

        navmove("/login");
      } else {
        setLoadingRegUser(false);
      }
    } else {
      setLoadingRegUser(false);
      setValidationCodeError("Incorrect code");
    }
  }

  const generateRandomCode = () => {
    // Generate a random 6-character validation code
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";
    for (let i = 0; i < 6; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return code;
  };
  // =================================================================
  const handleGenderChange = (event) => {
    setFormData({
      ...formData,
      gender: event.target.value, // Update the gender field based on the selected radio button
    });
  };

  const handleDateChange = (event) => {
    setFormData({
      ...formData,
      dateofbirth: event.target.value, // Update DateOfBirth in formData
    });
  };

  return (
    <div className="Section">
      <div className="section-container">
        <div className="section-container-left" data-aos="slide-up">
          <h1 className="Theader">Get your free bank account now.</h1>
          <img src={registerbkg} alt="" />
        </div>
        <div className="section-container-right" data-aos="slide-up">
          <p>{isCodeSent}</p>

          {!isCodeSent ? (
            <form
              className="form-container"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <div className="user-details">
                {formError && (
                  <div className="form-error-container">
                    <p className="error">
                      <i class="fa-solid fa-circle-exclamation"></i> {formError}
                    </p>
                  </div>
                )}

                <div>
                  <label htmlFor="firstName">First Name:</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    ref={firstName}
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="lastName">Last Name:</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    ref={lastName}
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <p>Gender</p>
                  <div className="genedercontainer">
                    <div>
                      <input
                        type="radio"
                        id="male"
                        name="gender"
                        value="Male"
                        checked={formData.gender === "Male"} // Check if this radio button is selected
                        onChange={handleGenderChange} // Update formData when clicked
                      />
                      <label htmlFor="male">Male</label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        id="female"
                        name="gender"
                        value="Female"
                        checked={formData.gender === "Female"} // Check if this radio button is selected
                        onChange={handleGenderChange} // Update formData when clicked
                      />
                      <label htmlFor="female">Female</label>
                    </div>
                  </div>
                </div>

                <div>
                  <p>Date of Birth</p>
                  <input
                    type="date"
                    value={formData.dateofbirth} // Bind the formData DateOfBirth to input value
                    onChange={handleDateChange} // Update formData on change
                  />
                 
                </div>

                <div>
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="tel"
                    id="mobilenumber"
                    name="mobilenumber"
                    // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    value={formData.mobilenumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <h2>Country:</h2>

                  <select
                    value={selectedCountry}
                    onChange={handleCountrySelect}
                  >
                    <option value="">Select a country</option>
                    {countryData.map((country, index) => (
                      <option key={index} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>

                  <p>Currency: {selectedCountryCurrency}</p>
                </div>

                <div>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    ref={email}
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    ref={password}
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="confirmPassword">Confirm Password:</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    ref={confirmPassword}
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="confrim-buttons">
                  <div className="confirmation-checkbox">
                    <input
                      type="checkbox"
                      name="terms"
                      checked={isTandCisChecked}
                      onChange={() => {
                        setTandC(!isTandCisChecked);
                      }}
                    />
                    <p> I have read the Terms and Condition </p>
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      if (!loadingRegEmail) {
                        handleSendCode();
                      }
                    }}
                  >
                    {loadingRegEmail ? (
                      <i className="fa-solid fa-spinner fa-spin spinner"></i>
                    ) : (
                      <p>Next</p>
                    )}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <div>
              <div className="user-details">
                {validationCodeError && (
                  <div className="form-error-container">
                    <p className="error">
                      <i class="fa-solid fa-circle-exclamation"></i>{" "}
                      {validationCodeError}
                    </p>
                  </div>
                )}

                <div>
                  <label htmlFor="validationCode">Validation Code :</label>
                  <input
                    type="text"
                    value={emailValidationCode}
                    onChange={(e) => setEmailValidationCode(e.target.value)}
                  />
                  <p>Please check your email</p>
                </div>

                <button
                  type="button"
                  onClick={() => {
                    if (!loadingRegUser) {
                      registerNewUser();
                    }
                  }}
                >
                  {loadingRegUser ? (
                    <i className="fa-solid fa-spinner fa-spin spinner"></i>
                  ) : (
                    <p> Complete Registration</p>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
