import React, { useState } from "react";
import '../styles/PinModal.css';
import { baseurl } from "../utils/constants";
import axios from "axios";
import { useSelector } from "react-redux";

function FundsLockModal({ isOpen, setIsOpen }) {
  const userData = useSelector((state) => state.userdata);
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [error, setError] = useState("");

  // Validation logic when submitting
  

  

  return isOpen ? (
    <div className="modal">
      <div className="modal-content">
        <h1>Funds Locked</h1>
          <p style={{color:'red'}}>Your acount funds has been locked for security violations. please contact support.</p>
     
      </div>
    </div>
  ) : null;
}

export default FundsLockModal;
